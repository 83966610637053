import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#03C9D7");
  const [currentMode, setCurrentMode] = useState(localStorage.getItem("themeMode")||"Light");
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [auth, setAuth] = useState(sessionStorage.getItem("id")!==null?true:false);
  const [token, setToken] = useState("");
  const [viewData, setViewData] = useState([]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [banner, setBanner] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [reset,setReset]=useState(false)
  const [displayOTP,setDisplayOTP]=useState(false)
  const [registerInfo,setRegisterInfo]=useState(false)
  const [changePwd,setChangePwd]=useState(false)
  const [deliveryRateSelectMerchant,setDeliveryRateSelectMerchant]=useState([])
  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem("themeMode", e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem("colorMode", color);
  };

  const handleClick = (clicked) =>
    setIsClicked({ ...initialState, [clicked]: true });

  return (
    <StateContext.Provider
      value={{
        currentColor,
        currentMode,
        activeMenu,
        screenSize,
        setScreenSize,
        handleClick,
        isClicked,
        initialState,
        setIsClicked,
        setActiveMenu,
        setCurrentColor,
        setCurrentMode,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings,
        auth,
        setAuth,
        token,
        setToken,
        viewData,
        setViewData,
        data,
        setData,
        value,
        setValue,
        profileData,
        setProfileData,
        banner,
        setBanner,
        orderData, setOrderData,
        reset,setReset,
        registerInfo,setRegisterInfo,                                    
        displayOTP,setDisplayOTP,changePwd,setChangePwd,
        deliveryRateSelectMerchant,setDeliveryRateSelectMerchant
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export default StateContext;
export const useStateContext = () => useContext(StateContext);
