import React,{useState,useContext, useEffect} from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from 'axios';
import { showErrorMsg } from "../../components/helper/message"
import { showLoader } from "../../components/helper/loading";
import StateContext from '../../contexts/ContextProvider';
import NewLogo from "../../assets/orbit.png";
import Container from "@mui/material/Container";
import { API } from "../../config/api";
const theme = createTheme();
const OTPVerify = (props) => {

   const {registerInfo,setRegisterInfo,setChangePwd,setDisplayOTP} = useContext(StateContext);
   const [errMsg,setErrMsg]=useState(false)
   const [loading, setLoading] = useState(false);
//   const [user,setUser]=useState([])
//   useEffect(async()=>{
// await axios.get(`${API}/auth/byDistributor/${registerID}`).then(res=>setUser(res.data))
//   },[])
var timeout;


  const displayErrMsg=(setErrMsg, timeout)=> {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    },1000);
    return timeout;
  }

// const showErrorMessage = (msg) => {
//   return (
//     <div class="alert alert-danger" role="alert">
//  {msg}
//   </div>
//   )
// }
   console.log(registerInfo)
  const [otpData, setOtpData] = useState({
     otp1: "", otp2: "", otp3: "", otp4: ""
  });

  const {  otp1, otp2, otp3, otp4} =otpData;
  // const handleChange=(value1, event)=> {

  //   setOtpData({ [value1]: event.target.value });
  // }
const resendOTP=async()=>{
  setErrMsg(false)
 await axios.get(`${API}/auth/resend/otp/${registerInfo.mobile}`).then(res=>
  setRegisterInfo({otp:res.data.data,
    mobile:registerInfo.mobile}))
}
console.log(registerInfo.otp)
  const handleChange = (e) => {
    const {value}=e.target;

    if(validNumber(value)||e.key === "Backspace"){
      setOtpData({
       ...otpData,
      [e.target.name]: e.target.value,
       })
      }
  };
const validNumber=(num)=>{

  const numberRegEx = /\-?\d*\.?\d{1,2}/;
  return numberRegEx.test(String(num).toLowerCase());
  
}
  const handleSubmit=async(event)=> {
    
    event.preventDefault();
    setLoading(true);
    let otp=otp1+otp2+otp3+otp4

    if(otp==registerInfo.otp){
      const config={headers:{"Content-Type":"application/json"}}
      let data={
        mobile: parseInt(registerInfo.mobile),
        otp: registerInfo.otp.toString()
      }
      
      await axios.post(`https://api.orbitmart.co.in/api/v1/auth/verify-mobile`,data,config).then((res)=>{
        // console.log(res)
        setLoading(false)
        
        setDisplayOTP(false)
        setChangePwd(true)

      })
    
      }
      // if(user.data.otp==otp)
     else{
      setLoading(false)
      setErrMsg(true)
      
      // setMsg(res.data.message);
timeout = displayErrMsg(setErrMsg, timeout);
}
    
  }

  const inputFocus = (elmnt) => {
    // elmnt.key === "Delete" ||
    if (elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {

        elmnt.target.form.elements[next].focus()
       
      }
      setOtpData({
        ...otpData,
       [elmnt.target.name]: "",
        })
    }
    else {
      // console.log("next");
        if(validNumber(elmnt.target.value))
        {
          const next = elmnt.target.tabIndex;
          if (next < 4) {
            elmnt.target.form.elements[next].focus()
          }
        }
       
    }

  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {errMsg && showErrorMsg("Invalid OTP", errMsg)}
        <Box
          sx={{
            //marginTop: 20,
            //margin: "auto",
            marginTop: "23%",
            marginBottom: "23%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "25px",
            padding: 5,
            border: "2px solid #003c7e",
          }}
        >
         
         <img src={NewLogo} style={{ height: "50%" }} />

       
                    <Box
                sx={{
                  my: 4,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                }}
              >
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ color: "black", pb: 2,textAlign:"center" }}
                >
                  OTP verification
                </Typography>

                <Typography
                  component="p"
                  variant="tbody"
                  sx={{ color: "black", pb: 2,textAlign:"center",textTransform:"lowercase" }}
                >
                  {/* {user.data.mobile} */}
                  we have send verification code to <b>+91 {registerInfo.mobile}</b>
                </Typography>
                <Grid item sm={12} component={Paper} elevation={0} square>
              <Box
                sx={{
     
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                   
                }}
              >
                 {loading && showLoader()}
                 <br/>
                <Box component="form" onSubmit={handleSubmit} >
            
    <div className="otpContainer">

      <input
        name="otp1"
        // type="text"
        autoComplete="off"
        className="otpInput"
         value={otp1}
        //  onkeypress={validNumber}
        onChange={handleChange}
        onKeyUp={e => inputFocus(e)}
        tabIndex="1" maxLength="1" 
        autoFocus
      />
      <input
        name="otp2"
        // type="text"
        autoComplete="off"
        className="otpInput"
        value={otp2}
        onChange={handleChange}
        // onkeypress={e=>validNumber(e)}
        onKeyUp={e => inputFocus(e)}
        tabIndex="2" maxLength="1" 

      />
      <input
        name="otp3"
        // type="text"
        autoComplete="off"
        className="otpInput"
        value={otp3}
                // onkeypress={e=>validNumber(e)}
        onKeyUp={e => inputFocus(e)}
        onChange={handleChange}

        tabIndex="3" maxLength="1" 

      />
      <input
        name="otp4"
        // type="text"
        autoComplete="off"
        className="otpInput"
        value={otp4}
       
        onChange={handleChange}
        onKeyUp={e => inputFocus(e)}
        tabIndex="4" maxLength="1" 
      />

   
    </div>
  
    <Box sx={{ mt: 5 }}>
                    <div className="card ">
                    


                      <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
           Verify
          </Button>
                      <h6 className="text-center pt-3 text-muted">Don't received OTP?</h6>
                 
                      <Button
            type="button"
            fullWidth
            variant="text"
            sx={{ mt: 3, mb: 2 }}  onClick={resendOTP}   >
                      Resend OTP
          </Button>
                    </div>
                  </Box>
                  </Box>
                  </Box>
               
                  </Grid>

          
                </Box>
 

          
        </Box>
        </Container>
        </ThemeProvider>
      



  )
}

export default OTPVerify