import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';


export const showErrorMsg = (msg,errMsg) => {
return(
<Snackbar
  open={errMsg !== ''}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
>
  <Alert variant="filled" severity="error">
    <strong>{msg}</strong>
  </Alert>
</Snackbar>
)}

export const showSuccessMsg = (msg,successMsg) => {
  return(
  <Snackbar
  open={successMsg}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
  
   ><Alert variant="filled" severity="success">
  
   <strong>  {msg}</strong>
  </Alert></Snackbar>
  )}
