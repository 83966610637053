import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { BsFillPencilFill } from "react-icons/bs";
import { AiOutlineFolderView } from "react-icons/ai";
import { FiEye } from "react-icons/fi";

const gridItemView = () => (
  <div className="flex items-center justify-center gap-2">
    <h1
      style={{ color: "#8be78b", display: "flex" }}
      class="font-medium leading-tight text-xl mt-0 mb-2"
    >
      <Link style={{ marginRight: "auto" }} to="/promotionaldiscount/edit">
        <BsFillPencilFill />
      </Link>
    </h1>
    <h1
      style={{ color: "#03c9d7", display: "flex" }}
      class="font-medium leading-tight text-xl mt-0 mb-2"
    >
      <Link
        style={{ marginLeft: "auto", marginRight: "auto" }}
        to="/promotionaldiscount/view"
      >
        <FiEye />
      </Link>
    </h1>
    <h1
      style={{ color: "#ff5c8e", display: "flex" }}
      className="font-small leading-tight text-xl mt-0 mb-2 "
    >
      <Link
        style={{ marginLeft: "auto", marginRight: "auto" }}
        to="/promotionaldiscount"
      >
        <AiFillDelete />
      </Link>
    </h1>
  </div>
);

export const itemsGrid = [
  {
    field: "id",
    headerText: "Id",
    textAlign: "Center",
    isPrimaryKey: true,
  },
  {
    field: "merchant",
    headerText: "Merchant",

    textAlign: "Center",
  },
  {
    field: "percentagediscount",
    headerText: "Percentage Discount",

    textAlign: "Center",
  },
  {
    field: "validfrom",
    headerText: "Valid From",
    textAlign: "Center",
  },
  {
    field: "validto",
    headerText: "Valid To",
    textAlign: "Center",
  },
  {
    field: "action",
    headerText: "Actions",

    textAlign: "Center",
    template: gridItemView,
  },
];

export const contextMenuItems = [
  "AutoFit",
  "AutoFitAll",
  "SortAscending",
  "SortDescending",
  "Copy",
  "Edit",
  "Delete",
  "Save",
  "Cancel",
  "PdfExport",
  "ExcelExport",
  "CsvExport",
  "FirstPage",
  "PrevPage",
  "LastPage",
  "NextPage",
];

export const itemsData = [
  {
    id: 1,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 2,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 3,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 4,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 5,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 6,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 7,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 8,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 9,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 10,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 11,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 12,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 13,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 14,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 15,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 16,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 17,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 18,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 19,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
  {
    id: 20,
    merchant: "Shree",
    percentagediscount: "10%",
    validfrom: "2022-05-05 18:20:00",
    validto: "2023-05-05 18:20:00",
  },
];
