import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../view.css";
import { Alert, Stack } from "@mui/material";
import { ImCross } from "react-icons/im";
import { BsFillPencilFill } from "react-icons/bs";
import blank from "../../assets/blank.jpg";
import Pincode from "react-pincode";
import { showErrorMsg } from "../../components/helper/message";
import { API } from "../../config/api";

const AddEmployee = () => {
  const navigate = useNavigate();
  const initialState = {
    firstname: "",
    lastname: "",
    delivery: "",
    gender: "",
    address: "",
    pincode: "",
    mobile: "",
    email: "",
    password: "",
    country: "",
    state: "",
    city: "",
    employee: "",
    addharcard: "",
    panCard: "",
    bankdetails: "",
    branchname: "",
    accountno: "",
    accountype: "",
    ifscode: "",
    status: "",
    activeinactive: "",
  };
  const [addData, setAddData] = useState(initialState);
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [password, setPassword] = useState(0);
  const [mobile, setMobile] = useState(0);
  const [pincode, setPincode] = useState(0);
  const [adharcard, setAdharCard] = useState(0);
  const [pancard, setPanCard] = useState(0);
  const [selectedImages, setSelectedImages] = useState([]);
  const [pincodeData, setPincodeData] = useState([]);
  const [country, setCountry] = useState("India");



  const [errMsg, setErrMsg] = useState(false);
  const [msg, setMsg] = useState("");
  var timeout;
  const displayErrMsg=(setErrMsg, timeout)=> {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    },2000);
    return timeout;
  }
  console.log(pincodeData);

  const mainPage = () => {
    navigate("/employees");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      let length = document.getElementById("password").value.length;
      setPassword(length);
      setAddData({ ...addData, [name]: value });
    } else if (name === "mobile") {
      let length = document.getElementById("mobile").value.length;
      setMobile(length);
      setAddData({ ...addData, [name]: value });
    } else if (name === "pincode") {
      let length = document.getElementById("pincode").value.length;
      setPincode(length);
      setAddData({ ...addData, [name]: value });
    } else if (name === "panCard") {
      let length = document.getElementById("pancard").value.length;
      setPanCard(length);
      setAddData({ ...addData, [name]: value });
    } else if (name === "addharcard") {
      let length = document.getElementById("adharcard").value.length;
      setAdharCard(length);
      setAddData({ ...addData, [name]: value });
    } else {
      setAddData({ ...addData, [name]: value });
    }
  };

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files[0];

    const fileExtension = selectedFiles.name.split(".").at(-1);
    const allowedFileTypes = ["jpg","jpeg","png"];
    if (!allowedFileTypes.includes(fileExtension)) {
        //window.alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
          //     alert("Only jpg/jpeg and png files are allowed!");
          setMsg(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
          timeout = displayErrMsg(setErrMsg, timeout);
          setLoading(false)
        return false;
    }
   else {
        setSelectedImages((previousImages) =>
          previousImages.concat(selectedFiles)
        );
        event.target.value = "";
   }
    //   // here you can do whatever you want with your image. Now you are sure that it is an image
    }
  

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

//   async function handleSubmit() {


//     if((addData.password=="")||(addData.email=="")||(addData.mobile==""))
//     {
//       setMsg("All fields are required");
//       timeout = displayErrMsg(setErrMsg, timeout);
// return false
//     }
//    else
//    {
//     const uploadImage = "https://api.orbitmart.co.in/api/v1/upload";
//     const uploadData =
//       "https://api.orbitmart.co.in/api/v1/auth/employee/merchant/register";
//     const formData = new FormData();
//     //const imageFinal = image;
//     let token = sessionStorage.getItem("myToken");
//     formData.append("image", selectedImages[0]);
//      await axios.post(uploadImage, formData).then((res) => {
//        console.log(res);
//       const newimg = res.data.files;
//       const coverPhoto = newimg;
//       const finalData = {
//         email: addData.email,
//         mobile: addData.mobile,
//         password: addData.password,
//         firstName: addData.firstname,
//         lastName: addData.lastname,
//         deliveryPerRate: addData.delivery,
//         state: pincodeData.stateName,
//         city: pincodeData.city,
//         district: pincodeData.district,
//         pincode: pincodeData.pincode,
//         country: country,
//         UPI: addData.upi,
//         gender: addData.gender,
//         address: addData.address,
//         addharCard: addData.addharcard,
//         panCard: addData.panCard,
//         bankName: addData.bankdetails,
//         role: "EMPLOYEE",
//         accountNo: addData.accountno,
//         bankAccountType: addData.accountype,
//         branchName: addData.branchname,
//         ifscCode: addData.ifscode,
//         empStatus: addData.employee,
//         ActiveInactive: false,
//         avatar: coverPhoto,
//         verified: true,
//       };
//       console.log("hgg",finalData);
//       const requestOptions = {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "x-token": token,
//         },
//         body: JSON.stringify(finalData),
//       };
//       fetch(uploadData, requestOptions)
      
//         .then((res) => {
//           console.log('add emp',res)
//           if (res.status === 201) {
//             setAlert(true);
//             setTimeout(() => {
//               mainPage();
//             }, 1000);
//           } else {
//             setError(true);
//             setTimeout(() => {
//               mainPage();
//             }, 1000);
//           }
//         })
//         .catch((err) => {
//           setError(true);
//           setTimeout(() => {
//             mainPage();
//           }, 1000);
//         });
//     });
//    }
 
//   }

async function handleSubmit() {
  if (addData.password === "" || addData.email === "" || addData.mobile === "") {
    setMsg("All fields are required");
    timeout = displayErrMsg(setErrMsg, timeout);
    return false;
  } else {
    const uploadImage = `${API}/upload`;
    const uploadData = `${API}/auth/employee/merchant/register`;
    const formData = new FormData();
    let token = sessionStorage.getItem("myToken");

    formData.append("image", selectedImages[0]);

    try {
      const res = await axios.post(uploadImage, formData);
      console.log(res);
      const newimg = res.data.files;
      const coverPhoto = newimg;

      const finalData = {
        email: addData.email,
        mobile: addData.mobile,
        password: addData.password,
        firstName: addData.firstname,
        lastName: addData.lastname,
        deliveryPerRate: addData.delivery,
        state: pincodeData.stateName,
        city: pincodeData.city,
        district: pincodeData.district,
        pincode: pincodeData.pincode,
        country: country,
        UPI: addData.upi,
        gender: addData.gender,
        address: addData.address,
        addharCard: addData.addharcard,
        panCard: addData.panCard,
        bankName: addData.bankdetails,
        role: "EMPLOYEE",
        accountNo: addData.accountno,
        bankAccountType: addData.accountype,
        branchName: addData.branchname,
        ifscCode: addData.ifscode,
        empStatus: addData.employee,
        ActiveInactive: false,
        avatar: coverPhoto,
        verified: true,
      };

      console.log("hgg", finalData);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: JSON.stringify(finalData),
      };

      const response = await fetch(uploadData, requestOptions);

      if (response.status === 201) {
        setAlert(true);
        setTimeout(() => {
          mainPage();
        }, 1000);
      } else {
        setError(true);
        setTimeout(() => {
          mainPage();
        }, 1000);
      }
    } catch (error) {
      setError(true);
      setTimeout(() => {
        mainPage();
      }, 1000);
    }
  }
}


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
         
          {errMsg && showErrorMsg(msg, errMsg)}
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            This is a success alert — check it out!
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="failure">
            Sorry, Merchant Cannot be Updated at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Add Employee
      </h4>
      <div class="flex space-x-2 justify-end">
        <button type="button" class="backButton" onClick={() => mainPage()}>
          Back
        </button>
      </div>
      <form>
        <div className="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>First Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="firstname"
              name="firstname"
              onChange={handleChange}
              placeholder="Enter First Name"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Last Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="lastname"
              name="lastname"
              onChange={handleChange}
              placeholder="Enter Last Name"
            />
          </div>
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Per Delivery Rate</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="delivery"
              name="delivery"
              onChange={handleChange}
              placeholder="Enter Per Delivery Rate"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
            // for="name"
            // class="form-label inline-block mb-2 text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="email"
              name="email"
              onChange={handleChange}
              placeholder="Enter Email Address"
              required
            />
            <p class="invisible peer-invalid:visible text-red-700 font-light">
              Please Enter Your Valid Email Address
            </p>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Password</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="password"
              name="password"
              onChange={handleChange}
              placeholder=" Enter Password"
            />
            {password >= 6 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                You Have 6 Digit Password
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter Atleast 6 Digit Password
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Mobile Number</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="mobile"
              name="mobile"
              onChange={handleChange}
              placeholder="Enter Mobile Number"
              required
            />
            {mobile == 10 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                You ave 10 digit mobile number
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter Your Valid Mobile Number
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Gender</label>
            <select
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repea
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="gender"
              name="gender"
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option value="" disabled selected>Select Gender</option>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
            </select>
          </div>


          <div class="relative z-0 p-2 w-full mb-6 group">
            <label> Pin Code</label>
            <Pincode
             
              getData={(data) => setPincodeData(data)}
              pincodeInput={{
                width: "100%",
                height: 38,
                border: "1px solid lightgrey",
                borderRadius: 5,
                padding:"10px" ,    
                }}
                invalidError="Please check pincode"
                Container={{display:"flex",flexDirection:"column-reverse"}}
              showArea={false}
              showCity={false}
              showState={false}
              showDistrict={false}
              placeholder="Enter Your Pincode"
            />
          </div>

         
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Address</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="address"
              name="address"
              onChange={handleChange}
              placeholder="Enter Full Address"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>City</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="city"
              name="city"
              value={pincodeData.city}
              placeholder="Enter Your City"
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>District</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="district"
              name="district"
              value={pincodeData.district}
              placeholder="Enter Your District"
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>State</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="state"
              name="stateName"
              value={pincodeData.stateName}
              placeholder="Enter Your State"
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Country</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="country"
              name="country"
              value={country}
              placeholder="Enter Your Country"
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Aadhar Card</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="adharcard"
              name="addharcard"
              onChange={handleChange}
              placeholder="Enter Aadhar Card Number"
              required
            />
            {adharcard == 12 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                You Have 12 Digit Aadhar Card Number
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter Your Valid 12 Digit Aadhar Card Number
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Pan Card</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="pancard"
              name="panCard"
              onChange={handleChange}
              placeholder="Enter Pan Card Number"
              required
            />
            {pancard == 10 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                You Have 10 Digit Valid Pan Card Number
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter Your Valid 10 Digit Pan Card Number
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Bank Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="bankname"
              name="bankdetails"
              onChange={handleChange}
              placeholder="Enter Bank Name"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Branch Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="branchname"
              name="branchname"
              onChange={handleChange}
              placeholder="Enter Branch Name"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Account Type</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="acctype"
              name="accountype"
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option value="" disabled selected>Select Account Type</option>
              <option value="SAVING">Saving Account</option>
              <option value="CURRENT">Current Account</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Account Number</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="accnumber"
              name="accountno"
              onChange={handleChange}
              placeholder="Enter Account Number"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>UPI No</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="upi"
              value={addData.upi}
              onChange={handleChange}
              placeholder="Enter UPI No"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>IFSC Code</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="ifsccode"
              name="ifscode"
              onChange={handleChange}
              placeholder="Enter IFSC Code"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Employee Type</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="employee"
              name="employee"
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option value="" disabled selected>Select Employee Type</option>
              <option value="SELF">Self</option>
              <option value="OTHER">Individual</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Employee Status</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="status"
              name="status"
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option value="" disabled selected >Select Employee Status</option>
              <option value="FREE">Free</option>
              <option value="BUSY">Busy</option>
            </select>
          </div>

          <div>
            <label style={{ paddingLeft: "12px" }}>Image</label>
            <div class="relative mt-1 flex justify-center w-40">
              <div class=" absolute right-0 top-0 z-10 flex">
                <label>
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    // accept="image/jpeg, image/png"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={blank} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="flex space-x-2 justify-end">
        <button
          style={{ width: "8%" }}
          type="submit"
          onClick={handleSubmit}
          class="addButton"
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddEmployee;
