import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { AiFillDelete } from "react-icons/ai";
import { RiErrorWarningLine } from "react-icons/ri";
import Modal from "@mui/material/Modal";
import "../../pages/view.css";
import { Link } from "react-router-dom";

import { render } from "react-dom";
import { useNavigate } from "react-router-dom";
import { Alert, Stack } from "@mui/material";
import StateContext from "../../contexts/ContextProvider";
import { useContext } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 250,
  bgcolor: "background.paper",
  border: "2px solid redious #000",
  boxShadow: 24,
  p: 4,
};

const DeleteBannerModel = (props) => {
  const [open, setOpen] = React.useState(false);

  const [data, setData] = useState([""]);

  const navigate = useNavigate();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const mainPage = () => {
    //  navigate("/banners");
    navigate("/Banners");
  };
  const { setValue } = useContext(StateContext);

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  // const itemDelete = (itemId) => {
  //   const id = Object.values(itemId);
  //   fetch(`https://api.orbitmart.co.in/api/v1/banner/${id}`, {
  //     method: "DELETE",
  //   })
  //     .then(async (response) => {
  //       const data = await response.json();
  //       console.log(data);

  //       setValue(data.code);

  //       if (!response.ok) {
  //         // get error message from body or default to response status
  //         const error = (data && data.message) || response.status;
  //         return Promise.reject(error);
  //       }
  //       console.log("Delete successful");
  //     })
  //     .catch((error) => {
  //       console.error("There was an error!", error);
  //     });
  //   handleClose();
  //   mainPage();
  // };
  //  useEffect(()=>{itemDelete()},[])

  return (
    // <div>
    //   <button
    //     style={{ marginTop: 1, alignItems: "center" }}
    //     onClick={handleOpen}
    //   >
    //     <AiFillDelete />
    //   </button>
    //   <Modal
    //     open={open}
    //     onClose={handleClose}
    //     aria-labelledby="modal-modal-title"
    //     aria-describedby="modal-modal-description"
    //   >
    //     <Box sx={style}>
    //       <div
    //         style={{ color: "#ff5c8e", display: "flex" }}
    //         className="font-2xl leading-tight text-6xl mt-0 mb-2  flex space-x-2 justify-center"
    //       >
    //         <RiErrorWarningLine />
    //       </div>

    //       <h1 class="font-bold leading-tight  flex space-x-2 justify-center align-center text-xl mt-0 mb-0 text-black-600">
    //         Are you sure?
    //       </h1>

    //       <h4 class="flex space-x-2 justify-center aline-center">
    //         You wan't be able to revert this!
    //       </h4>

    //       <br />

    //       <div class="flex space-x-2 justify-center aline-center ml-10 pl-10">
    //         <button class="backButton" onClick={handleClose}>
    //           Cancel
    //         </button>
    //         <button class="addButton" onClick={() => itemDelete(itemId)}>
    //           Yes, Delete!
    //         </button>
    //       </div>
    //     </Box>
    //   </Modal>
    // </div>

    <div>
      <button type="button" onClick={ handleOpen }>

        <AiFillDelete />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="!rounded ">
          <div
            style={{ color: "red", display: "flex" }}
            className="font-2xl leading-tight text-6xl mt-0 mb-2  flex space-x-2 justify-center"
          >
            <RiErrorWarningLine />
          </div>

          <h1 class="font-bold leading-tight  flex space-x-2 justify-center align-center text-xl mt-0 mb-0 text-black-600">
            Are you sure?
          </h1>

          <h4 class="flex space-x-2 justify-center aline-center">
            you won't be able to revert this!
          </h4>

          <br />
          <div className="flex space-x-2 justify-center items-center m-0 p-0">
            <button
              type="button"
              className="backButton !ml-0"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="addButton "
              onClick={ () =>{props.itemDelete(props.itemId);
                handleClose();
                mainPage();}}
            >
              Yes,delete it!
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteBannerModel;
