import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { register } from "../../contexts/actions/register";
import RegisterUI from "./RegisterUI";
import UseForm from "./UseForm";

const RegisterContainer = () => {
  useEffect(() => {}, []);

  return <RegisterUI form={UseForm()} />;
};

export default RegisterContainer;
