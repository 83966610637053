import { Alert, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import { ImCross } from "react-icons/im";
import { BsFillPencilFill } from "react-icons/bs";
import "../view.css";
import Blank from "../../assets/blank.jpg";
import axios from "axios";
import { showErrorMsg } from "../../components/helper/message";
import { API } from "../../config/api";
const EditBanner = () => {
  const navigate = useNavigate();

  const { banner } = useContext(StateContext);
  const [addData, setAddData] = useState(banner);
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(banner);
  const [imageLength, setImageLength] = useState(data.coverPhoto.length);
  const [selectedImages, setSelectedImages] = useState(data.coverPhoto);
  const [newImages, setNewImages] = useState([]);
  const [finalImages, setFinalImages] = useState([]);
  const [errMsg, setErrMsg] = useState(false);
  const [msg, setMsg] = useState("");
  var timeout;
  const displayErrMsg = (setErrMsg, timeout) => {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    }, 2000);
    return timeout;
  };
  const imagesCoverphoto = [];

  // console.log(selectedImages);

  // console.log(banner);

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files[0];

    const fileExtension = selectedFiles.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "jpeg", "png"];
    if (!allowedFileTypes.includes(fileExtension)) {
      //window.alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
      //     alert("Only jpg/jpeg and png files are allowed!");
      setMsg(
        `File does not support. Files type must be ${allowedFileTypes.join(
          ", "
        )}`
      );
      timeout = displayErrMsg(setErrMsg, timeout);
      setLoading(false);
      return false;
    } else {
      setSelectedImages((previousImages) =>
        previousImages.concat(selectedFiles)
      );
      event.target.value = "";
    }
    // const selectedFiles = event.target.files[0];
    // setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // event.target.value = "";
    // debugger;
    // const selectedFiles = event.target.files[0];
    // console.log(selectedFiles);
    // setSelectedImages((previousImages) => previousImages.concat(selectedFiles));

    // event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
    setImageLength(selectedImages.length);
  }

  const onNewSelectFile = (event) => {
    const selectedFiles = event.target.files[0];

    const fileExtension = selectedFiles.name.split(".").at(-1);
    const allowedFileTypes = ["jpg", "jpeg", "png"];
    if (!allowedFileTypes.includes(fileExtension)) {
      //window.alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
      //     alert("Only jpg/jpeg and png files are allowed!");
      setMsg(
        `File does not support. Files type must be ${allowedFileTypes.join(
          ", "
        )}`
      );
      timeout = displayErrMsg(setErrMsg, timeout);
      setLoading(false);
      return false;
    } else {
      const selectedFiles = event.target.files;
      const selectedFilesArray = Array.from(selectedFiles);
      const imagesArray = selectedFilesArray.map((file) => {
        return file;
      });
      setNewImages((previousImages) => previousImages.concat(imagesArray));
      // FOR BUG IN CHROME
      event.target.value = "";
    }
  };
  // console.log(data,">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");

  function deleteHandlerNew(image) {
    setNewImages(newImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  // console.log(imageLength);

  const mainPage = () => {
    navigate("/Banners");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddData({ ...addData, [name]: value });
  };

  function postData(e) {
e.preventDefault(); 
    // debugger;

    let id = addData._id;
    const uploadAPI = `${API}/upload`;
    //const token = sessionStorage.getItem("myToken");
    const formData = new FormData();
    for (let i = 0; i < newImages.length; i++) {
      formData.append("Image", newImages[i]);
    }
    // console.log("NewImages", newImages);
    // console.log("formdata", formData);
    axios.post(uploadAPI, formData).then((res) => {
      debugger;
      
      var newimg = res.data.files;
      var coverPhoto = newimg;
      const finalImages = [...selectedImages, ...coverPhoto];
      // console.log(coverPhoto);
      if (coverPhoto.length != 0) {
        var finalData = {
          bannerName: addData.bannerName,
          locationName: addData.locationName,
          coverPhoto: finalImages,
        };
      } else {
        var finalData = {
          bannerName: addData.bannerName,
          locationName: addData.locationName,
          coverPhoto: selectedImages,
        };
      }

      // console.log("finalData +++++++++++++++++++++++++++++++++++++", finalData);

      axios
        .put(`${API}/banner/${id}`, finalData)
        .then((res) => {
          debugger;
          // console.log("checking / updating banner API",res);
          if (res.status == 201) {
            setAlert(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          } else {
            setError(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          }
        })
        .catch((err) => {
          console.error("There was an error!", err);
        });
    });
  }

  function multipleImg() {
    debugger;
    return banner.map((data) => <img src={data.coverPhoto} alt="banner" />);
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {errMsg && showErrorMsg(msg, errMsg)}
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Banner Updated SuccessFully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="failure">
            Sorry, Banner Cannot be Updated at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 className="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Edit Banner
      </h4>
      <div className="flex space-x-2 justify-end">
        <button
          type="button"
          className="backButton"
          style={{ width: "8%" }}
          onClick={() => mainPage()}
        >
          Back
        </button>
        {/* <button
          type="button"
          className="addButton"
          style={{ width: "8%" }}
          onClick={postData}
        >
          Update
        </button> */}
      </div>
      <form onSubmit={postData}>
      
        <div className="grid xl:grid-cols-2  m:gap-2">
          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Banner Name</label>
            <input required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="bannername"
              name="bannerName"
              onChange={handleChange}
              value={addData.bannerName}
              placeholder="Enter Banner Name"
            />
          </div>

          <div className="relative z-0 p-2 w-full mb-6 group">
            <label>Location Name</label>
            <input required
              type="text"
              className="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="locationname"
              name="locationName"
              value={addData.locationName}
              onChange={handleChange}
              placeholder="Enter Location Name"
            />
          </div>

          <div>
            <label style={{ paddingLeft: "12px" }}>Image</label>
            <div className="relative mt-1 flex justify-center w-40">
              <div className=" absolute right-0 top-0 z-10 flex">
                {/* <label>
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    className="sr-only"
                  />
                </label> */}
              </div>
              <div className="relative z-0 p-2 w-full mb-6 group">
                {
                  selectedImages.length > 0 ? (
                    selectedImages.map((item, index) => {
                      return (
                        <div className="row" key={index}>
                          <p className="block-icon">
                            <img src={item} alt="img" />
                            <ImCross
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteHandler(item)}
                              className="icon-belowtag"
                            />
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <img src={Blank} alt="banner" />
                  )

                  // <img
                  //   src={banner.coverPhoto.map((data) => data)}
                  //   alt="banner"
                  // />
                }
              </div>
            </div>
          </div>

          <div>
            <label style={{ paddingLeft: "12px" }}>Add Image</label>
            <div className="relative mt-1 flex justify-center w-40">
              <div className=" absolute right-0 top-0 z-10 flex">
                <label>
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    accept="image/*"
                    type="file"
                    onChange={onNewSelectFile}
                    className="sr-only"
                  />
                </label>
              </div>
              <div className="relative z-0 p-2 w-full mb-6 group">
                {newImages.length > 0 ? (
                  newImages.map((item, index) => {
                    return (
                      <div className="row" key={index}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandlerNew(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <img src={Blank} alt="banner" />
                )}
              </div>
            </div>
          </div>
        </div>
        <button
          className="addButton absolute right-20 bottom-40"
          style={{ width: "8%" }}
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default EditBanner;
