import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import StateContext, { useStateContext } from "../../contexts/ContextProvider";
import axios from "axios";
import { ImCross } from "react-icons/im";
import "../view.css";
import { Alert, Stack } from "@mui/material";
import { BsFillPencilFill } from "react-icons/bs";
import blank from "../../assets/blank.jpg";
import Pincode from "react-pincode";
import { showErrorMsg } from "../../components/helper/message";
import { Container } from '@mui/material/Container';
import { API } from "../../config/api";
const AddMerchant1 = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [type, setType] = useState({});
  const [classify, setClassify] = useState("");
  const [alltype, setAllType] = useState([]);
  const [pincode, setPincode] = useState(0);
  const [password, setPassword] = useState(0);
  const [mobile, setMobile] = useState(0);
  const [adharcard, setAdharCard] = useState(0);
  const [pancard, setPanCard] = useState(0);
  const [id, setId] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [pincodeData, setPincodeData] = useState([]);
  const [country, setCountry] = useState("India");

  const [errMsg, setErrMsg] = useState(false);
  const [msg, setMsg] = useState("");
  var timeout;
  const displayErrMsg=(setErrMsg, timeout)=> {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    },2000);
    return timeout;
  }
  console.log(pincodeData);

  useEffect(() => {
    DomainType();
  }, []);

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files[0];

    const fileExtension = selectedFiles.name.split(".").at(-1);
    const allowedFileTypes = ["jpg","jpeg","png"];
    if (!allowedFileTypes.includes(fileExtension)) {
        //window.alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
          //     alert("Only jpg/jpeg and png files are allowed!");
          setMsg(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
          timeout = displayErrMsg(setErrMsg, timeout);
          setLoading(false)
        return false;
    }
   else {
        setSelectedImages((previousImages) =>
          previousImages.concat(selectedFiles)
        );
        event.target.value = "";
   }
    // const selectedFiles = event.target.files[0];
    // setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  function DomainType() {
    axios.get(`${API}/merchant/Type`).then((res) => {
      const domainType = res.data.data;
      setType(domainType);
    });
  }

  function check() {
    setAllType([]);
    let domainType = type;
    var merchantType = classify;
    if (merchantType !== "") {
      domainType.map((subDomain) => {
        if (subDomain.merchantType == merchantType) {
          setAllType((alltype) => [...alltype, subDomain]);
        }
      });
    } else {
      setAllType([]);
    }
  }
  console.log(alltype);

  const mainPage = () => {
    navigate("/merchants");
  };

  const initialState = {
    firstName: "",
    lastName: "",
    gender: "",
    shopName: "",
    classification: "",
    domain: "",
    address: "",
    pincode: "",
    mobile: "",
    email: "",
    password: "",
    country: "",
    state: "",
    city: "",
    commission: "",
    addharcard: "",
    panCard: "",
    bankdetails: "",
    branchname: "",
    accountype: "",
    role: "MERCHANT",
    accountno: "",
    ifscode: "",
    verified: true,
  };

  const [addData, setAddData] = useState(initialState);
  // const handleChange = (e) => {

  //    const { name, value } = e.target;
    
  //    if(name === "domain"){
       
  //      let index = e.target.selectedIndex;
  //      var idvalue = e.target.childNodes[index].id 
  //      //setAddData({ ...addData, [name]: idvalue });  
  //      setUpdatedValue({ ...updatedValue, [name]: value });
  //    }
  //    else if(name === "classification"){
  //      setClassify(value);
  //      setAllType([]);
  //    }
  //    else{
  //      //setAddData({ ...addData, [name]: value });
  //      setUpdatedValue({ ...updatedValue, [name]: value });
  //    }
  //    // setUpdatedValue({ ...updatedValue, [name]: value });
  //  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "domain") {
      let index = e.target.selectedIndex;
      var idvalue = e.target.childNodes[index].id;
      setAddData({ ...addData, [name]: idvalue });
    } else if (name === "classification") {
      setClassify(value);
      console.log(value,"asdfgv")
      setAllType([]);
    } else {
      if (name === "password") {
        let length = document.getElementById("password").value.length;
        setPassword(length);
        setAddData({ ...addData, [name]: value });
      } else if (name === "mobile") {
        let length = document.getElementById("mobile").value.length;
        setMobile(length);
        setAddData({ ...addData, [name]: value });
      } else if (name === "pincode") {
        let length = document.getElementById("pincode").value.length;
        setPincode(length);
        setAddData({ ...addData, [name]: value });
      } else if (name === "panCard") {
        let length = document.getElementById("pancard").value.length;
        setPanCard(length);
        setAddData({ ...addData, [name]: value });
      } else if (name === "addharcard") {
        let length = document.getElementById("adharcard").value.length;
        setAdharCard(length);
        setAddData({ ...addData, [name]: value });
      } else {
        setAddData({ ...addData, [name]: value });
      }
    }
  };

  async function handleSubmit() {
    const uploadImage = `${API}/upload`;
    const uploadData =
      `${API}/auth/employee/merchant/register`;
    const formData = new FormData();
    //const imageFinal = image;
    let token = sessionStorage.getItem("myToken");
    formData.append("Image", selectedImages[0]);
    axios.post(uploadImage, formData).then((res) => {
      console.log(res);
      const newimg = res.data.files;
      const coverPhoto = newimg[0];
      const finalData = {
        ActiveInactive: false,
        email: addData.email,
        mobile: addData.mobile,
        password: addData.password,
        firstName: addData.firstName,
        lastName: addData.lastName,
        gender: addData.gender,
        // classification: addData.classification,
        classification: classify,

        merchantTypeId: addData.domain,
        firmName: addData.shopName,
        country: addData.country,
        orbitMartCommission: addData.commission,
        address: addData.address,
        state: pincodeData.stateName,
        city: pincodeData.city,
        district: pincodeData.district,
        pincode: pincodeData.pincode,
        country: country,
        UPI: addData.upi,
        addharCard: addData.addharcard,
        panCard: addData.panCard,
        bankName: addData.bankdetails,
        bankAccountType: addData.accountype,
        role: "MERCHANT",
        accountNo: addData.accountno,
        branchName: addData.branchname,
        ifscCode: addData.ifscode,
        avatar: coverPhoto,
      };
      console.log(finalData);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: JSON.stringify(finalData),
      };
      fetch(uploadData, requestOptions)
        .then((res) => {
          if (res.status === 201) {
            setAlert(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          } else {
            setError(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          }
        })
        .catch((err) => {
          setError(true);
          setTimeout(() => {
            mainPage();
          }, 1000);
        });
    });
  }


  const domain = function (domain){
    return <option value ={domain.domainType} id={domain._id}>{domain.domainType}</option>}
  
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
           {errMsg && showErrorMsg(msg, errMsg)}
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Merchant Added Successfully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="error">
            Sorry, Merchant Cannot be Added at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h1 class="font-medium  flex space-x-2  leading-tight text-4xl  mt-0 mb-2 text-blue-600">
        Add Merchant
      </h1>

      <div class="flex space-x-2 justify-end  aline-end">
        <button
          type="button"
          onClick={() => mainPage()}
          class="backButton"
          style={{ width: "8%" }}
        >
          Back
        </button>
      </div>

      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Classification</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="classification"
              name="classification"
              onChange={handleChange}
              
              aria-label="Default select example"
            >
              <option value="" disabled selected>-- Select Classification --</option>
              <option value="WHOLESALER">Wholesaler</option>
              <option value="RETAILER">Retailer</option>
              <option value="MANIFACTURER">Manufacturer</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Type</label>
            <select
              class="form-select
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
               onClick={check}
              onChange={handleChange}
              // value= {updatedValue.merchantTypeId.domainType}
              id="domain"
              name="domain"
            >
              <option value="" disabled selected>-- Select Type --</option>
              {alltype.map(domain)}
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
            // for="name"
            // class="form-label inline-block mb-2 text-gray-700"
            >
              Firm Name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="shopname"
              name="shopName"
              onChange={handleChange}
              placeholder="Enter Company Name"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
            // for="name"
            // class="form-label inline-block mb-2 text-gray-700"
            >
              First Name
            </label>
            <input
              type="text"
              class="form-select appearance-none
              block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding bg-no-repeat
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="firstname"
              name="firstName"
              onChange={handleChange}
              placeholder="Enter First Name"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
            // for="name"
            // class="form-label inline-block mb-2 text-gray-700"
            >
              Last Name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="Lastname"
              name="lastName"
              onChange={handleChange}
              placeholder="Enter Last Name"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Gender</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="gender"
              name="gender"
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option value="" disabled selected>-- Select Gender --</option>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
            // for="name"
            // class="form-label inline-block mb-2 text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="email"
              name="email"
              onChange={handleChange}
              placeholder="Enter Email Address"
              required
            />
            <p class="invisible peer-invalid:visible text-red-700 font-light">
              Please Enter Your Valid Email Address
            </p>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
            // for="name"
            // class="form-label inline-block mb-2 text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="password"
              name="password"
              onChange={handleChange}
              placeholder=" Enter Password"
              required
            />
            {password >= 6 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                You have 6 Digit Password
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter Atleast 6 Digit Password
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
            // for="name"
            // class="form-label inline-block mb-2 text-gray-700"
            >
              Mobile Number
            </label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="mobile"
              name="mobile"
              onChange={handleChange}
              placeholder="Enter Mobile Number"
              required
              onInput = {(e) =>{
                e.target.value = (e.target.value).toString().slice(0,10)
            }}
            />
            {mobile == 10 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                You have 10 digit Mobile Number
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter Your Valid Mobile Number
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
            // for="name"
            // class="form-label inline-block mb-2 text-gray-700"
            >
              Orbit Commission
            </label>
            <input
              type="text"
              class="form-select appearance-none
              block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding bg-no-repeat
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="commission"
              name="commission"
              onChange={handleChange}
              placeholder="Enter Commission"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
            // for="name"
            // class="form-label inline-block mb-2 text-gray-700"
            >
              Address
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="address"
              name="address"
              onChange={handleChange}
              placeholder="Enter Your Address"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label> Pin Code</label>
            <Pincode
              
              getData={(data) => setPincodeData(data)}
              pincodeInput={{
                width: "100%",
                height: 38,
                border: "1px solid lightgrey",
                borderRadius: 5,
                padding:"10px" ,    
                }}
                invalidError="Please check pincode"
                Container={{display:"flex",flexDirection:"column-reverse"}}
              showArea={false}
              showCity={false}
              showState={false}
              showDistrict={false}
              placeholder="Enter Your Pincode"
            />
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label
            // for="name"
            // class="form-label inline-block mb-2 text-gray-700"
            >
              Pin Code
            </label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        peer border border-slate-400
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="pincode"
              name="pincode"
              onChange={handleChange}
              placeholder="Enter Pincode"
              required
            />
            {pincode == 6 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                You have Valid Pincode
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter 6 Digit Pincode Only
              </p>
            )}
          </div> */}

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>City</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="city"
              name="city"
              value={pincodeData.city}
              placeholder="Enter Your City"
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>District</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="district"
              name="district"
              value={pincodeData.district}
              placeholder="Enter Your District"
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>State</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="state"
              name="stateName"
              value={pincodeData.stateName}
              placeholder="Enter Your State"
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Country</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="country"
              name="country"
              value={country}
              placeholder="Enter Your Country"
              disabled
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Aadhar Card</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="adharcard"
              name="addharcard"
              onChange={handleChange}
              placeholder="Enter Aadhar Card Number"
              onInput = {(e) =>{
                e.target.value = (e.target.value).toString().slice(0,12)
            }}
            />
            {adharcard == 12 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                You have 12 digit Aadharcard Number
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter Your Valid 12 Digit Aadharcard Number
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>PAN Card</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="pancard"
              name="panCard"
              onChange={handleChange}
              placeholder="Enter Pan Card Number"
              onInput = {(e) =>{
                e.target.value = (e.target.value).toString().slice(0,10)
            }}
            />
            {pancard == 10 ? (
              <p class="peer-invalid:visible text-green-700 font-light">
                You have 10 digit valid Pan Card Number
              </p>
            ) : (
              <p class="peer-invalid:visible text-red-700 font-light">
                Please Enter Your Valid 10 Digit Pan Card Number
              </p>
            )}
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Bank Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="bankname"
              name="bankdetails"
              onChange={handleChange}
              placeholder="Enter Bank Name"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Branch Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="branchname"
              name="branchname"
              onChange={handleChange}
              placeholder="Enter Branch Name"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
            // for="name"
            // class="form-label inline-block mb-2 text-gray-700"
            >
              Bank Account Number
            </label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="accnumber"
              name="accountno"
              onChange={handleChange}
              placeholder="Enter Account Number"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Bank Account Type</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="acctype"
              name="accountype"
              onChange={handleChange}
              aria-label="Default select example"
            >
              <option value="" disabled selected>-- Select Account Type --</option>
              <option value="SAVING">Saving Account</option>
              <option value="CURRENT">Current Account</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>UPI No</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="upi"
              value={addData.upi}
              onChange={handleChange}
              placeholder="Enter UPI No"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
            // for="name"
            // class="form-label inline-block mb-2 text-gray-700"
            >
              IFSC Code
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="ifsccode"
              name="ifscode"
              onChange={handleChange}
              placeholder="Enter IFSC Code"
            />
          </div>
{/* 
          <div>
            <label style={{ paddingLeft: "12px" }}>Image</label>
            <div class="relative mt-1 flex justify-center w-40">
              <div class=" absolute right-0 top-0 z-10 flex">
                <label>
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={blank} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div> */}
        </div>
      </form>
      <div class="flex space-x-2 justify-end">
        <button
          style={{ width: "8%" }}
          type="submit"
          onClick={handleSubmit}
          class="addButton"
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddMerchant1;
