import { Alert, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import { ImCross } from "react-icons/im";
import { BsFillPencilFill } from "react-icons/bs";
import "../view.css";
import { API } from "../../config/api";

const EditDeliveryRate = () => {
  const navigate = useNavigate();
  const { viewData } = useContext(StateContext);
  const [addData, setAddData] = useState(viewData);
  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  console.log(addData);
  const onSelectFile = (event) => {
    const selectedFiles = event.target.files[0];
    setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  const mainPage = () => {
    navigate("/deliveryRate");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setAddData({ ...addData, [name]: value });
  };
  async function handleSubmit(e) {
    const editId = viewData._id;
    e.preventDefault();
    const res = await fetch(
      `${API}/deliveryRate/${editId}`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": "token-value",
        },
        body: JSON.stringify(addData),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          setAlert(true);
          setTimeout(() => {
            navigate("/deliveryRate");
          }, 1000);
        } else {
          setError(true);
          setTimeout(() => {
            navigate("/deliveryRate");
          }, 1000);
        }
      })
      .catch((err) => {
        setError(true);
        setTimeout(() => {
          navigate("/deliveryRate");
        }, 1000);
      });
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Delivery Rate Updated SuccessFully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="failure">
            Sorry, Delievery Rate Cannot be Updated at this moment. Please try
            Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Edit Delivery Rate
      </h4>
      <div class="flex space-x-2 justify-end">
        <button type="button" class="backButton" onClick={() => mainPage()}>
          Back
        </button>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="grid xl:grid-cols-2  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Charge of order amount between 1-199</label>
            <input
              required
              type="text"
              class="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="chargeForOrderAmountBetween_1_199"
              name="chargeForOrderAmountBetween_1_199"
              onChange={handleChange}
              value={addData.chargeForOrderAmountBetween_1_199}
              placeholder="Enter charge For Order Amount Between 1-199"
            />
          </div>
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Charge of order amount between 200-499</label>
            <input
              required
              type="text"
              class="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="chargeForOrderAmountBetween_200_499"
              name="chargeForOrderAmountBetween_200_499"
              onChange={handleChange}
              value={addData.chargeForOrderAmountBetween_200_499}
              placeholder="Enter charge of order amount between 200-499"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
              for="deliveryamount"
              class="form-label inline-block mb-2 text-gray-700"
            >
              Charge of order amount greater than 500
            </label>
            <input
              required
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="chargeForOrderAmountGreaterThan_500"
              name="chargeForOrderAmountGreaterThan_500"
              onChange={handleChange}
              value={addData.chargeForOrderAmountGreaterThan_500}
              placeholder="Enter charge of order amount greater than 500"
            />
          </div>
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Per km charge over 5km</label>
            <input
              required
              type="text"
              class="form-control
    block
    w-full
    px-3
    py-1.5
    text-base
    font-normal
    text-gray-700
    bg-white bg-clip-padding
    border border-solid border-gray-300
    rounded
    transition
    ease-in-out
    m-0
    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="perKmChargeOver_5Km"
              name="perKmChargeOver_5Km"
              onChange={handleChange}
              value={addData.perKmChargeOver_5Km}
              placeholder="Enter per km charge over 5km"
            />
          </div>
        </div>
        <div class="flex space-x-2 justify-end">
          <button class="addButton">Update</button>
        </div>
      </form>
      {/* <div class="flex space-x-2 justify-end">
    <button 
      type="button"  
      class="addButton"  
      onClick={handleSubmit} >
      Update
    </button>
  </div> */}
    </div>
  );
};

export default EditDeliveryRate;
