import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ImCross } from "react-icons/im";
import axios from "axios";
import StateContext from "../../contexts/ContextProvider";
import { showErrorMsg } from "../../components/helper/message";
//Alert
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import { API } from "../../config/api";

const AddBanner = () => {
  const navigate = useNavigate();

  //const { token } = useContext(StateContext);

  const { setValue } = useContext(StateContext);

  const mainPage = () => {
    navigate("/Banners");
  };
  const [errMsg, setErrMsg] = useState(false);
  const [msg, setMsg] = useState("");
  var timeout;
  const displayErrMsg=(setErrMsg, timeout)=> {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    },2000);
    return timeout;
  }
  const [bannerName, setBannerName] = useState("");
  const [locationName, setLocationName] = useState("");

  // const initialState = {
  //   bannerName: "",
  //   locationName: "",
  //   pincode: "",
  // };

  // const [addData, setAddData] = useState(initialState);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setAddData({ ...addData, [name]: value });
  //   console.log(addData);
  // };

  const [selectedImages, setSelectedImages] = useState([]);

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files[0];

    const fileExtension = selectedFiles.name.split(".").at(-1);
    const allowedFileTypes = ["jpg","jpeg","png"];
    if (!allowedFileTypes.includes(fileExtension)) {
        //window.alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
          //     alert("Only jpg/jpeg and png files are allowed!");
          setMsg(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
          timeout = displayErrMsg(setErrMsg, timeout);
          setLoading(false)
        return false;
    }
   else {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const imagesArray = selectedFilesArray.map((file) => {
      return file;
    });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    // FOR BUG IN CHROME
    event.target.value = "";
  }
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  function postData() {
    const uploadAPI = `${API}/upload`;
    const uploadData = `${API}/banner`;
    const token = sessionStorage.getItem("myToken");
    const formData = new FormData();
    for (let i = 0; i < selectedImages.length; i++) {
      formData.append("Image", selectedImages[i]);
    }
    console.log("selectedimages", selectedImages);
    console.log("formdata", formData);
    axios.post(uploadAPI, formData).then((res) => {
      console.log("upload res", res);
      var newimg = res.data.files;
      var coverPhoto = newimg;
      console.log(coverPhoto);
      console.log(bannerName);
      console.log(locationName);
      //console.log(pincode);
      var finalData = {
        bannerName: bannerName,
        locationName: locationName,
        coverPhoto: coverPhoto,
      };
      console.log("finalData", finalData);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", "x-token": token },
        body: JSON.stringify(finalData),
      };
      fetch(uploadData, requestOptions)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            setAlert(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          } else {
            setError(true);
            setTimeout(() => {
              mainPage();
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error.message);
          console.error("There was an error!", error);
        });
    });
  }

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
         {errMsg && showErrorMsg(msg, errMsg)}
      {alert ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="success">
            Banner Updated SuccessFully
          </Alert>
        </Stack>
      ) : null}
      {error ? (
        <Stack
          sx={{ width: "30%" }}
          style={{ position: "fixed", left: "22%", bottom: "1%", width: "30%" }}
          spacing={2}
        >
          <Alert variant="filled" severity="failure">
            Sorry, Banner Cannot be Updated at this moment. Please try Again!
          </Alert>
        </Stack>
      ) : null}
      <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Add Banner
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          class="backButton"
          style={{ width: 80 }}
          onClick={() => mainPage()}
        >
          Back
        </button>
        <button
          type="button"
          style={{ width: 80 }}
          onClick={postData}
          class="addButton"
        >
          Add
        </button>
      </div>
      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Banner Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="firstname"
              name="bannerName"
              onChange={(e) => setBannerName(e.target.value)}
              placeholder="Enter Banner Name"
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Location Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="locationName"
              onChange={(e) => setLocationName(e.target.value)}
              placeholder="Enter Your Location"
            />
          </div>

          <div>
            <label>Cover photo</label>
            <div class="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
              <div class="space-y-1 text-center">
                <svg
                  class="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="flex text-sm text-gray-600">
                  <label
                    for="file-upload"
                    class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                  >
                    <span>Upload a file</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      multiple
                      onChange={onSelectFile}
                      class="sr-only"
                    />
                  </label>
                  <p class="pl-1">or drag and drop</p>
                </div>
                <p class="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
              </div>
            </div>
          </div>
        </div>
        <div class="relative z-0 p-2 w-full mb-6 group">
          {selectedImages.length > 0 &&
            selectedImages.map((item, index) => {
              return (
                <div style={{ display: "inline-block" }} key={item}>
                  <p className="block-icon">
                    <img src={URL.createObjectURL(item)} alt="img" />
                    <ImCross
                      style={{
                        cursor: "pointer",
                        marginTop: 4,
                        marginRight: 4,
                      }}
                      onClick={() => deleteHandler(item)}
                      className="icon-tag"
                    />
                  </p>
                </div>
              );
            })}
        </div>
      </form>
    </div>
  );
};

export default AddBanner;