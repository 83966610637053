import firebase from "firebase/app";
import "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCHOqapDLehS0TP6nmks_TErX07wcfIHUQ",

  authDomain: "orbitmart-dev.firebaseapp.com",

  projectId: "orbitmart-dev",

  storageBucket: "orbitmart-dev.appspot.com",

  messagingSenderId: "964201207187",

  appId: "1:964201207187:web:33dfc60ca5234a29cac466",

  measurementId: "G-ZPMK24L0YF"
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const  REACT_APP_VAPID_KEY  = "BOtVaHZhIJSrTxLsnDzoKtb-Ru9tnGUCuJKFOIrLvcM_8O6FiEf_RNGB3HqxSmsfNW0uGGSY6gi-95KdQmViYdA";
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      // setTokenFound(true);
    } else {
      // setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
