import React, { useRef, useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "./orbitmart-logo.png";
import NewLogo from "../../assets/orbit.png";
import { Link } from "react-router-dom";
import auth from "../../contexts/reducers/auth";
import { showLoader } from "../../components/helper/loading";
import axios from "axios";
import { showSuccessMsg, showErrorMsg } from "../../components/helper/message";

import Typography from "@mui/material/Typography";
import * as Yup from "yup";
import { useFormik } from "formik";
import StateContext from "../../contexts/ContextProvider";
import { API } from "../../config/api";

const phoneRegex = /^\+([0-9]{1,3})*\.([0-9]{5,16})$/;
const theme = createTheme();

const ChangePWD = () => {
  const { setChangePwd, registerInfo } = useContext(StateContext);
  const [errMsg, setErrMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [msg, setMsg] = useState("");

  var timeout;
  const displaySuccessMsg = (setSuccessMsg, timeout) => {
    setSuccessMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setSuccessMsg(false);
    }, 1000);
    return timeout;
  };


  const validationSchema = Yup.object({
    npassword: Yup.string()
      .required("Please enter your new password")
      .min(6, "Password must be at least 6 characters"), 
      cpassword: Yup.string() 
      .required('Please retype your password')         
      .oneOf([Yup.ref('npassword')], 'Passwords must match')
       
  });



  const formik = useFormik({
    initialValues: { npassword: "", cpassword: "" },
    validationSchema: validationSchema,
    onSubmit: async (user) => {
      const data = {
        mobileno: registerInfo.mobile,
        otp: registerInfo.otp,
        password: formik.values.npassword,
      };
      const config = { headers: { "Content-Type": "application/json" } };
      await axios
        .post(
          `${API}/auth/forgotpassword`,
          data,
          config
        )
        .then((res) => {
          setMsg(res.data.message);
          setSuccessMsg(true);
          timeout = displaySuccessMsg(setSuccessMsg, timeout);
          setTimeout(function () {
            setChangePwd(false);
          }, 2000);
        });
    },
  });
  console.log(formik.values.npassword.length,"new pass length");
  console.log(formik.values.cpassword.length,"confirm pass length")
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {errMsg && showErrorMsg("Invalid OTP", errMsg)}
        {successMsg && showSuccessMsg(msg, successMsg)}
        <br />
        <Box 
          sx={{
            //marginTop: 20,
            //margin: "auto",
            marginTop: "23%",
            marginBottom: "23%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "25px",
            padding: 5,
            border: "2px solid #003c7e",
          }}
        >
          <img src={NewLogo} style={{ height: "50%" }} />

          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Typography
              component="h1"
              variant="h5"
              sx={{ color: "black", pb: 2, textAlign: "center" }}
            >
              Change Password
            </Typography>

            <TextField required
              variant="standard"
              fullWidth
              id="npassword"
              name="npassword"
              type="password"
              label="Enter New Password"
              value={formik.values.npassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.npassword && formik.touched.npassword}
            
              // error={formik.values.npassword.length?false:true}
              helperText={
                formik.errors.npassword && formik.touched.npassword
                  ? formik.errors.npassword
                  : ""
              }
              inputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
                style: { textTransform: "lowercase" },
              }}
            />
            <TextField required={true}
              variant="standard"
              fullWidth
              margin="normal"
              id="cpassword"
              name="cpassword"
              type="password"
              label="Enter Confirm Password"
              value={formik.values.cpassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.cpassword && formik.touched.cpassword}
              
              // error={formik.values.cpassword.length?false:true}
              helperText={
                formik.errors.cpassword && formik.touched.cpassword
                  ? formik.errors.cpassword
                  : ""
              }
              inputProps={{
                autoComplete: "con-password",
                form: {
                  autoComplete: "off",
                },
                style: { textTransform: "lowercase" },
              }}
            />
            <p className="policy">
              By continuing, you agree to OrbitMart's{" "}
              <Link className="lk" to="/privacypolicy" target="_blank">
                Terms of Use and Privacy Policy
              </Link>
            </p>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default ChangePWD;
