import React, { useState, useEffect } from 'react';
import { BsFillPencilFill } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { Button } from '@mui/material';

import { DataGrid, GridValueGetterParams, GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const Service = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const Navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddService = () => {
    Navigate('/Addservice');
  };

  const handleDeleteService = async (id) => {
    try {
      console.log('Deleting service with ID:', id);
      await fetch(`https://api.orbitmart.co.in/api/v1/service/${id}`, {
        method: 'DELETE',
      });

      setData((prevData) => prevData.filter((item) => item._id !== id));
    } catch (error) {
      console.error('Error deleting service:', error);
      setError('Error deleting service');
    }
  };

  const handleEditService = (Editservice) => {
    Navigate('/Editservice', { state: { Editservice } });
  };

  const fetchData = async () => {
    try {
      const response = await fetch('https://api.orbitmart.co.in/api/v1/service');
      if (!response.ok) {
        throw new Error('Error fetching data');
      }
      const responseData = await response.json();
      console.log('API Response:', responseData);
      setData(responseData.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Service page</h1>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
        <Button variant="contained" color="primary" onClick={handleAddService}>
          Add Service
        </Button>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Prescription</TableCell>
                <TableCell>Total Delivery Charge</TableCell>
                <TableCell>Total GST</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Pincode</TableCell>
                <TableCell>Payment Mode</TableCell>
                <TableCell>House Number</TableCell>
                <TableCell>Landmark</TableCell>
                <TableCell>Geo Latitude</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{row.prescription}</TableCell>
                  <TableCell>{row.totalDeliveryCharge}</TableCell>
                  <TableCell>{row.totalGST}</TableCell>
                  <TableCell>{row.address}</TableCell>
                  <TableCell>{row.pincode}</TableCell>
                  <TableCell>{row.paymentMode}</TableCell>
                  <TableCell>{row.houseNumber}</TableCell>
                  <TableCell>{row.landMark}</TableCell>
                  <TableCell>{row.geoLatitute}</TableCell>
                  <TableCell>
                    <Button
                     
                      color="secondary"
                      onClick={() => handleDeleteService(row._id)}
                    >
                      <AiFillDelete/>
                    </Button>
                    <Button
                    
                      onClick={() => handleEditService(row._id)}
                    >
                      <BsFillPencilFill/>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default Service;
