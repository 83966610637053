import React, { useState, useEffect, useContext } from "react";
import {
  AppBar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { BiSearch } from "react-icons/bi";
import axios from "axios";

import { Link } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { Header } from "../../components";
import { useNavigate } from "react-router-dom";
import "../../pages/view.css";
// import { itemsGrid, itemsData, contextMenuItems, CustomPaginationActions,handleChangePage,handleChangeRowsPerPage } from "./MerchantsConfig";
import MerchantMapping from "./MerchantMapping";
import StateContext from "../../contexts/ContextProvider";
import DeleteMerchantModal from "./DeleteMerchantModal";
import NewToggle from "../newToggle/NewToggle";
import "./pincode.css";
import { Button } from "@material-ui/core";
import { API } from "../../config/api";
import {
  FirstPageOutlined,
  LastPageOutlined,
  NavigateBefore,
  NavigateNext,
} from "@material-ui/icons";



const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse", 
  },
  cell: {
    padding: "8px", 
    textAlign: "start",
    // border: "1px solid " + theme.palette.divider,
  },
  cellRow: {
    // border: "1px solid " + theme.palette.divider,
    padding: "8px", 
    textAlign: "start",
  },
}));

const Merchants = () => {
  const [tDatas, setTDatas] = useState([]);
  const classes = useStyles();
  const { setViewData } = useContext(StateContext);
  const { setData } = useContext(StateContext);
  const { value, setValue } = useContext(StateContext);
  const selectionsettings = { persistSelection: true };
  const editing = { allowDeleting: true };
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0); 
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const addPage = () => {
    navigate("/Add/merchant");
  };

  const random = 201;
  // const fetchData = async () => {
  //   let id = sessionStorage.getItem("id");
  //   try {
  //     const response = await axios.get(
  //       `${API}/auth/merchant/byDistributorId/${id}`
  //     );
  //     setTDatas(response.data);
  //     setValue(random);
  //   } catch (e) {
  //     console.error("Error fetching", e);
  //   }
  // };
  const fetchData = async () => {
    let id = sessionStorage.getItem("id");
    if (id !== undefined) {
      try {
        const response = await axios.get(
          `${API}/auth/merchant/byDistributorId/${id}`
        );
        setTDatas(response.data);
        // setValue(random);
      } catch (e) {
        console.error("Error fetching", e);
      }
    } else {
      console.error("ID is undefined");
    }
  };
 

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const storedPage = sessionStorage.getItem('currentPageMerchant');
    if (storedPage !== null) {
      setCurrentPage(parseInt(storedPage, 10));
    }
  }, []);
 
  useEffect(() => {
    sessionStorage.setItem('currentPageMerchant', currentPage.toString());
  }, [currentPage]);

  // if (value === 200) {
  //   fetchData();
  //   setValue(random);
  // }

  const responseData = tDatas?.data || [];

  const filteredData = responseData.filter(item => item?.firmName.toLowerCase().includes(searchQuery.toLowerCase()));
  console.log(filteredData, "filteredData filteredData")

  const itemId = tDatas?.data;
  //
  const gridActions = (itemId) => (
    <div className="flex items-center  gap-2">
      <h1
        style={{ color: "#8be78b", display: "flex" }}
        className="font-small leading-tight text-xl mt-0 mb-2"
      >
        <Link style={{ marginRight: "auto" }} to="/merchants/edit">
          <BsFillPencilFill onClick={() => itemEdit(itemId)} />
        </Link>
      </h1>
      <h1
        style={{ color: "#03c9d7", display: "flex" }}
        className="font-small leading-tight text-xl mt-0 mb-2 "
      >
        <Link
          style={{ marginLeft: "auto", marginRight: "auto" }}
          to="/merchants/view"
        >
          <FiEye onClick={() => itemView(itemId)} />
        </Link>
      </h1>
      <button
        style={{ color: "#ff5c8e", display: "flex", marginTop: 2.5 }}
        className="font-small leading-tight text-xl mt-0 mb-2 "
      >
        <DeleteMerchantModal itemId={itemId._id} />
      </button>
      {/* <h1
        style={{ color: "#ff5c8e", display: "flex" }}
        className="font-small leading-tight text-xl mt-0 mb-2 "
      >
        <Link style={{ marginLeft: "auto", marginRight: "auto" }} to="/merchants">
          <DeleteMerchantModal itemId={itemId._id} />
        </Link>
      </h1> */}
    </div>
  );

  const handleChangePage = (event, newPage) => {
    if (newPage === 0) {
     setCurrentPage(0);
   } else if (newPage === Math.ceil(responseData?.length / pageSize) - 1) {
     setCurrentPage(Math.ceil(responseData?.length / pageSize) - 1);
   } else {
     setCurrentPage(newPage);
   }
  };
  
   const handleChangeRowsPerPage = (event) => {
   setPageSize(parseInt(event.target.value, 5));
   setCurrentPage(0);
  };
  
   const CustomPaginationActions = (props) => {
    
    console.log('Custom', props)
    const { count, page, rowsPerPage, onPageChange } = props;
    // const handleFirstPageButtonClick = (event) => {
    //   setLoading(true);
    //   onPageChange(event, 0);
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 500);
    // };
     const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    // const handleLastPageButtonClick = (event) => {
    //   setLoading(true);
      // onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 500);
    // };
     const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    const handleNextButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page + 1);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
  
    const handleBackButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page - 1);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 500);
    };
  
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
          <FirstPageOutlined />
        </Button>
        <Button onClick={handleBackButtonClick} disabled={page === 0}>
          <NavigateBefore />
        </Button>
        <span className="myPage">{currentPage + 1}</span>
        <Button
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <NavigateNext />
        </Button>
        <Button
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <LastPageOutlined />
        </Button>
      </div>
    );
  };
  

  const itemEdit = (itemId) => {
    const editId = itemId._id;
    console.log(editId);
    setData(itemId);
  };

  const itemView = (itemId) => {
    const viewId = itemId._id;
    console.log(viewId);
    setViewData(itemId);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(0);
  }

  // onChange={window.location.reload()}

  const gridStatus = (tDatas) => {
    let status = tDatas.ActiveInactive;
    const handleCellClick = () => {
      // setTimeout(() => {
      //   window.location.reload();
      // }, 500);
    };
    return (
      <div
        style={{ marginLeft: 5 }}
        className="flex items-center gap-2"
        onClick={handleCellClick}
      >
        <NewToggle status={status} tDatas={tDatas} />
      </div>
    );
  };

  if (itemId != undefined) {
    var totalData = itemId.length;
    var srIndex = itemId;
    // console.log(totalData);
    // console.log(srIndex);
  }

  const gridSrno = (itemId) => {
    var sr = itemId.index;
    sr++;
    return sr;
  };

  const currentPageData = filteredData?.slice(
    currentPage * pageSize,
    currentPage * pageSize + pageSize
  );

    console.log("current page", currentPage);
    console.log("page size", pageSize);
    console.log("total data", filteredData?.length);
    console.log(Math.ceil(filteredData?.length / pageSize));

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      {/* <Header title="Merchant List"  /> */}
      <div className="flex space-x-2 m-4 justify-end">
        {/* <MerchantMapping /> */}
        <button type="button" onClick={() => addPage()} className="addBtn">
          New Record
        </button>
        {/* <button class="addButton" type="button" onClick={() => addPage()}>
          Merchant Mapping
        </button> */}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 0 20px 0",
          padding: "8px",
          borderRadius: "20px",
          background: "rgb(0, 60, 126)",
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <h1 className="m-4" style={{ color: "white", fontSize: "1.25rem" }}>
          Merchant List ({responseData ? responseData.length : 0})
        </h1>
        <span
          className="flex space-x-2 m-4 justify-end"
          style={{ background: "white", borderRadius: "6px" }}
        >
          <input
            style={{
              padding: "4px 10px",
              outline: "none",
              borderRadius: "6px 0 0 6px",
              color: "black",
            }}
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <BiSearch
            style={{
              fontSize: "25px",
              color: "gray",
              cursor: "pointer",
              margin: "auto",
              paddingRight: "5px",
            }}
          />{" "}
        </span>
      </div>
      {console.log(currentPageData, "responseData safs")}
      <TableContainer className={currentPageData?.length > 0 ? "table-container" : ""}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellRow}>Id</TableCell>
              <TableCell className={classes.cellRow}>Firm Name</TableCell>
              <TableCell className={classes.cellRow}>Name</TableCell>
              <TableCell className={classes.cellRow}>Classification</TableCell>
              <TableCell className={classes.cellRow}>Pincode</TableCell>
              <TableCell className={classes.cellRow}>Active</TableCell>
              <TableCell className={classes.cellRow}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPageData?.length > 0 ? (
              currentPageData.map((item, i) => (
                <TableRow key={item._id}>
                  <TableCell className={classes.cell}>{i+1}</TableCell>
                  <TableCell className={classes.cell}>{item?.firmName}</TableCell>
                  <TableCell className={classes.cell}>{item?.firstName}</TableCell>
                  <TableCell className={classes.cell}>{item?.classification}</TableCell>
                  <TableCell className={classes.cell}>{item?.pincode}</TableCell>
                  <TableCell className={classes.cell}>{gridStatus(item)}</TableCell>
                  <TableCell className={classes.cell}>{gridActions(item)}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No Data Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {responseData?.length > 0 ? (
          <TablePagination
            count={responseData.length}
            component="div"
            page={currentPage}
            rowsPerPage={pageSize}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10]}
            showFirstButton={true}
            showLastButton={true}
            ActionsComponent={CustomPaginationActions}
          />
        ) : null}
      </TableContainer>
    </div>
  );
};
export default Merchants;
