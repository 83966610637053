import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ToggleSwitch.css";
 
export default function ToggleSwitch({ profileData }) {
  console.log({profileData})
  // const [state, setState] = useState(profileData.distributorActiveInactive);

  const [state, setState] = useState(profileData?.ActiveInactive);
  const adminStatus = profileData?.adminActiveInactive;

  console.log("Active Inactive ADMIN status:", adminStatus )
 
  // useEffect(() => {
  //   const storedState = localStorage.getItem("toggleState");
  //   if (storedState !== null) {
  //     setState(JSON.parse(storedState));
  //   }
  // }, []);
 
  useEffect(() => {
    if (profileData) {
      setState(profileData.ActiveInactive); // Set the initial state based on profileData
    }
  }, [profileData]);
 
  // function handleChange() {
  //   const newState = !state;
  //   setState(newState);
  //   localStorage.setItem("toggleState", JSON.stringify(newState));
  // }
 
  // function handleChange(e) {
  //   const uploadData = "/api/v1/auth/setstatus/distributor";
  //   const finalData = {
  //     ActiveInactive: !state,
  //   };
  //   axios
  //     .put(uploadData, finalData, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "x-token": token,
  //       },
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       setState(!state);
  //       localStorage.setItem("toggleState", JSON.stringify(!state));
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
 
  const handleChange = async () => {
    const newState = !state;
    setState(newState);
    localStorage.setItem("toggleState", JSON.stringify(newState));
 
    // Call the API to update distributorActiveInactive status
    try {
      const token = sessionStorage.getItem("myToken"); // Get token from local storage
      const response = await axios.put(
        "https://api.orbitmart.co.in/api/v1/auth/setstatus/distributor",
        { ActiveInactive: newState },
        {
          headers: {
            "x-token": `${token}`, // Attach token to the request headers
          },
        }
      );
      console.log(token);
      console.log("API Response:", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
 
  console.log({ state });

  const onOffStatusHandle =  adminStatus && state;
 
  return (
    <label className="toggle-switch">
      <input type="checkbox" checked={onOffStatusHandle} onChange={handleChange} />
      <span className="toggle-switch-slider"></span>
     </label>
   );
}