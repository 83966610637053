import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineRollback } from "react-icons/ai";
import "../../pages/view.css";
import { useNavigate, useParams } from "react-router-dom";
import { useState,useEffect } from "react";
import axios from "axios";
import { API } from "../../config/api";


const ViewAppUsers = () => {
  const [view, setView] = useState();
  const [successData, setSuccessData] = useState();

  const { id } = useParams();
  console.log("TableDataID", id);
  // c - 0;

  async function fetchData() {
    axios
      .get(`${API}/order`)
      .then((res) => {
        // console.log("first++", res.data.data);
        setSuccessData(res.data.data);
      })
      .catch((err) => {
        console.log("error++++", err.message);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div class="container">
      {successData?.map((item,index)=>{
        return(
          <div key={index}>
        {item._id === id ? (

   
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <div class="flex justify-end active">
          <div class="backIcon">
            <Link to="/appusers">
              <AiOutlineRollback />
            </Link>
          </div>
        </div>
        <div class="flex justify-center text-left">
          <ul class="bg-white rounded-lg w-96 text-gray-900">
            <li class="px-6 py-2 border-b border-gray-200 w-full rounded-t-lg">
              Client Name :{item?.userId?.firstName}
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Mobile No : {item?.userId?.mobile}
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Email : {item?.userId?.email}
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Gender : {item?.userId?.gender}
              
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Pincode :  {item?.userId?.pincode}
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Registered Media : 
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Registered At : {item?.userId?.createdAt}
            </li>
            <li className="px-6 py-2 w-full rounded-b-lg">
              Client Status :
            </li>
          </ul>
        </div>
      </div>
      ) : null}
      </div>
      );
         })}
    </div>
  );
};

export default ViewAppUsers;
