import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { AiFillDelete } from "react-icons/ai";
import { FcApproval } from "react-icons/fc";
import Modal from "@mui/material/Modal";
import "../../pages/view.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  height: 250,
  bgcolor: "background.paper",
  border: "2px solid redious #000",
  boxShadow: 24,
  p: 4,
};

const ConfirmPopup = ({ open, setConfirmOpen }) => {
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  const handleClose = () =>{
     setConfirmOpen(false);
    //  window.location.reload();
    }

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      {/* <button
        type="button"
        style={{ marginTop: 1, alignItems: "center" }}
        // onClick={handleOpen}
      >
        
        <AiFillDelete />
      </button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{ color: "#ff5c8e", display: "flex" }}
            className="font-2xl leading-tight text-6xl mt-0 mb-2  flex space-x-2 justify-center"
          >
            <FcApproval />
          </div>

          <h1 class="font-bold leading-tight  flex space-x-2 justify-center align-center text-xl mt-0 mb-0 text-black-600">
            You have accepted order
          </h1>

          <h4 class="font-bold leading-tight  flex space-x-2 justify-center align-center text-xl mt-0 mb-0 text-black-600">
            successfully
          </h4>

          <br />

          <div class="flex space-x-2 justify-center aline-center">
            {/* <button type="button" class="backButton" >
              Cancel
            </button> */}
            <button type="button" class="addButton" onClick={handleClose}>
              ok
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmPopup;
