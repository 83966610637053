import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineRollback } from "react-icons/ai";
import "../../pages/view.css";

const ViewOnlineTransaction = () => {
  return (
    <div class="container">
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <div class="flex justify-end active">
          <div class="backIcon">
            <Link to="/onlinetransaction">
              <AiOutlineRollback />
            </Link>
          </div>
        </div>
        <div class="flex justify-center text-left" >
          <ul class="bg-white rounded-lg w-96 text-gray-900 ">
            <li class="px-6 py-2 border-b border-gray-200 w-full rounded-t-lg">
              Payment Gateway : payU
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Transaction Purpose : Load Wallet
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Transaction Amount : 500
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Transaction Status : Done
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Transaction PaymentId : HLTV
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Order Id : LW222222222222
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Customer Name : Jackz Hunter
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Date Time : 08/05/2024
            </li>
            <li class="px-6 py-2 border-b border-gray-200 w-full">
              Payment Status : Pending
            </li>
            <li class="px-6 py-2 w-full rounded-b-lg">PayoutDescription: </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ViewOnlineTransaction;
