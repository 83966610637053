import React, { useState, useEffect, useContext } from "react";
import "./tablecss.css";
import { Link } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import { BiSearch } from "react-icons/bi";
import OrderDelete from "./config/OrderDelete";
import { itemsGrid, itemsData, contextMenuItems } from "./config/OrderConfig";

// import { itemsData, contextMenuItems, itemsGrid } from "../../assets/dummy";
import { Header } from "../../components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import StateContext from "../../contexts/ContextProvider";
import {
  AppBar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { gridConfirm,gridItemAction } from './config/OrderConfig'
import { Button } from "@material-ui/core";
import {
  FirstPageOutlined,
  LastPageOutlined,
  NavigateBefore,
  NavigateNext,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    borderCollapse: "collapse", 
  },
  cell: {
    padding: "8px", 
    textAlign: "start",
    // border: "1px solid " + theme.palette.divider,
  },
  cellRow: {
    // border: "1px solid " + theme.palette.divider,
    padding: "8px", 
    textAlign: "start",
  },
}));
// format date
const formatDate = (dateString) => {
  // Parse the date string
  const parsedDate = new Date(dateString);
 
  // Check if the parsed date is valid
  if (!isNaN(parsedDate.getTime())) {
 
    // Format the date as "DD/M/YYYY"
    const day = parsedDate.getDate();
    const month = parsedDate.getMonth() + 1;
    const year = parsedDate.getFullYear();
 
    return `${day}/${month}/${year}`;
  } else {
    // If parsing fails, return the original date string
    return dateString;
  }
};

const Orders = () => {
  const distId = sessionStorage.getItem("id");
  const classes = useStyles();
  const { orderData, setOrderData } = useContext(StateContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('PENDING');
  const [currentPage, setCurrentPage] = useState(0); 
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [TotalData,setTotalData] = useState('')
  const [paymentAmountDetail, setPaymentAmountDetail] = useState('')


  function fetchOrders() {
    axios

      .get(`https://api.orbitmart.co.in/api/v1/order/user/${distId}`)
      .then((res) => {
        // let data=res.data.data.map((itemId)=>{ itemId.grandTotal=getAmount(itemId);return itemId})
        // setTDatas(data)

        // let data = res.data
        //   .filter((or) => or.distributorId == distId)
        //   .reverse()
          // .map((itemId) => {
          //   itemId.grandTotal = getAmount(itemId);
          //   return itemId;
          // });
        setTotalData(res.data)
        console.log("hello orderdata", res.data.data, "adsfbsdcvfdreffbrweffdhrg");
        setOrderData(res.data.data);
      })
      .catch((err) => {
        console.log("orderdata error", err.message);
      });
  }
  useEffect(() => {
    fetchOrders();
    paymentAmountDetails()
  }, []);

  console.log("total", TotalData, orderData)

  const totalPriceDATA = orderData.map((totalPrice) => totalPrice.totalPrice)

  const totalAmountData = totalPriceDATA.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  console.log(totalAmountData)

  // const filteredData = orderData.filter(item => item?.userId?.firstName.toLowerCase().includes(searchQuery.toLowerCase()));
  // console.log(filteredData, "filteredData filteredData")

useEffect(() => {
  const storedPage = sessionStorage.getItem("currentPageOrder");
  if (storedPage !== null) {
    setCurrentPage(parseInt(storedPage, 10));
  }

  const storedStatus = sessionStorage.getItem("selectedStatusorder");
  if (storedStatus !== null) {
    setSelectedStatus(storedStatus);
  }
}, []);
useEffect(() => {
  sessionStorage.setItem("currentPageOrder", currentPage.toString());
}, [currentPage]);

  const selectionsettings = { persistSelection: true };
  const editing = { allowDeleting: true };
  const toolbarOptions = ["Search"];


  const filteredOrders = selectedStatus ? orderData.filter((item) => item.orderStatus === selectedStatus): orderData;


  const handleChangePage = (event, newPage) => {
    if (newPage === 0) {
     setCurrentPage(0);
   } else if (newPage === Math.ceil(filteredOrders?.length / pageSize) - 1) {
     setCurrentPage(Math.ceil(filteredOrders?.length / pageSize) - 1);
   } else {
     setCurrentPage(newPage);
   }
  };
  
   const handleChangeRowsPerPage = (event) => {
   setPageSize(parseInt(event.target.value, 5));
   setCurrentPage(0);
  };
  
   const CustomPaginationActions = (props) => {
    
    console.log('Custom', props)
    const { count, page, rowsPerPage, onPageChange } = props;
    // const handleFirstPageButtonClick = (event) => {
    //   setLoading(true);
    //   onPageChange(event, 0);
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 500);
    // };
     const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    // const handleLastPageButtonClick = (event) => {
    //   setLoading(true);
      // onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 500);
    // };
     const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    const handleNextButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page + 1);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    };
  
    const handleBackButtonClick = (event) => {
      setLoading(true);
      onPageChange(event, page - 1);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 500);
    };
  
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button onClick={handleFirstPageButtonClick} disabled={page === 0}>
          <FirstPageOutlined />
        </Button>
        <Button onClick={handleBackButtonClick} disabled={page === 0}>
          <NavigateBefore />
        </Button>
        <span className="myPage">{currentPage + 1}</span>
        <Button
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <NavigateNext />
        </Button>
        <Button
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        >
          <LastPageOutlined />
        </Button>
      </div>
    );
  };


  const navigate = useNavigate();

  const addPage = () => {
    navigate("/manageItem/add");
  };

  // const getAmount=(itemId)=>{
  //     return ((itemId.totalPrice)-(itemId.productDetails.map((pr)=>{

  //      if(pr?.productId?.discountType=='FIXED')
  //      {
  //        return  parseInt(pr.productId.customerDiscount)
  //      }
  //      else{
  //        return  (parseInt(pr.productId?.itemPrice)*parseInt(pr.productId?.customerDiscount!==""?pr.productId?.customerDiscount:0))/100
  //      }
  //    }).reduce((a,b)=>a+b))+ (((itemId.totalPrice*parseInt(itemId.productDetails[0].productId?.gst))/100))+20+itemId?.productDetails
  //    ?.map((pr) => {
  //      return parseInt(pr?.productId?.orbitMartCommission);
  //    })
  //    .reduce((a, b) => a + b)).toFixed(0)
  //    }
  const getAmount = (itemId) => {
    const subtotal = itemId?.productDetails
      ?.map((pro) => {
        return pro.productId?.itemPrice * pro?.quantity;
      })
      .reduce((a, b) => a + b)||0;
    const customerDiscount = itemId?.productDetails
      ?.map((pr) => {
        if (pr?.productId?.discountType == "FIXED") {
          return parseFloat(pr?.productId?.customerDiscount);
        } else {
          return (
            (parseFloat(pr?.productId?.itemPrice) *
              parseFloat(pr?.quantity) *
              parseFloat(pr?.productId?.customerDiscount)) /
            100
          );
        }
      })
      .reduce((a, b) => a + b)||0;
    const GstLogic =
      ((subtotal - customerDiscount) *
        parseFloat(itemId?.productDetails?.[0].productId?.gst)) /
      100||0;
    const DeliveryAmount = 0;
    const TotalAmount = (
      subtotal -
      customerDiscount +
      GstLogic +
      DeliveryAmount
    ).toFixed(0);
    return TotalAmount;
  };


  const filteredData = filteredOrders.map((item) => {
    const userName = item?.userId?.firstName ?? '';
    const order = item?.orderNo ?? '';
  
    return {
      ...item,
      userId: {
        ...item?.userId,
        firstName: userName,
      },
      orderNo: order,
    };
  }).filter((item) => {
    const firstNameMatches = item?.userId?.firstName.toLowerCase().includes(searchQuery.toLowerCase());
    const orderNoMatches = item?.orderNo?.toString().includes(searchQuery);

    return firstNameMatches || orderNoMatches;
  });
  
  const currentPageData = filteredData?.slice(
    currentPage * pageSize,
    currentPage * pageSize + pageSize
  );
  const paymentAmountDetails = async()=> {
    const token = sessionStorage.getItem('myToken')
    const config = {
      headers: {
        'x-token': token
      }
    };
    try {
      const response = await axios.get('https://api.orbitmart.co.in/api/v1/order/paymentdetails', config);
    setPaymentAmountDetail(response.data)
    }
    catch (error) {
      console.log(error)
    }
  } 

  const handleTotalAmount = (amount) => {
    if (typeof amount !== 'number') {
      return 'Invalid amount';
    }
    const totalAmount = amount
   return totalAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
  }

  const amount = TotalData?.totalPrice;
  const commission = TotalData?.totalCommission;
  const totalAmount = handleTotalAmount(amount)
  const totalCommission = handleTotalAmount(commission)
  // const addFilterBtn = status => setSelectedStatus(status);
const addFilterBtn = (status) => {
    setSelectedStatus(status);
    sessionStorage.setItem('selectedStatusorder', status);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(0);
  }

  return (
    <div class="tableflex">
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <h3
          style={{ color: "#B2A59B" }}
          class="font-medium leading-tight text-3xl mt-0 mb-2 "
        >
          Total Order Amount :{" "}
          <span style={{ color: "#607274" }}>{Math.round(paymentAmountDetail.data?.totalAmount)}</span> & Total
          Commission :<span style={{ color: "#607274" }}> {Math.round(paymentAmountDetail.data?.totalComission)} </span>
        </h3>
        {console.log(orderData, "orderData")}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0 0 20px 0",
            padding: "8px",
            borderRadius: "20px",
            background: "rgb(0, 60, 126)",
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
          }}
        >
          <h1 className="m-4" style={{ color: "white", fontSize: "1.25rem" }}>
            Order List ({filteredOrders ? filteredOrders.length : 0})
          </h1>
          <span
            className="flex space-x-2 m-4 justify-end"
            style={{ background: "white", borderRadius: "6px" }}
          >
            <input
              style={{
                padding: "4px 10px",
                outline: "none",
                borderRadius: "6px 0 0 6px",
                color: "black",
              }}
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <BiSearch
              style={{
                fontSize: "25px",
                color: "gray",
                cursor: "pointer",
                margin: "auto",
                paddingRight: "5px",
              }}
            />{" "}
          </span>
        </div>

        <div className="flex space-x-2 m-4 justify-start">
        <button
            type="button"
            onClick={() => addFilterBtn("PENDING")}
            className={`order_addBtn ${
              selectedStatus === "PENDING" ? "active_order_addBtn" : ""
            }`}
          >
            PENDING
          </button>

        <button
            type="button"
            onClick={() => addFilterBtn("PROCESSING")}
            className={`order_addBtn ${
              selectedStatus === "PROCESSING" ? "active_order_addBtn" : ""
            }`}
          >
            PROCESSING
          </button>

          <button
            type="button"
            onClick={() => addFilterBtn("PACKAGING")}
            className={`order_addBtn ${
              selectedStatus === "PACKAGING" ? "active_order_addBtn" : ""
            }`}
          >
            PACKAGING
          </button>

          <button
            type="button"
            onClick={() => addFilterBtn("SHIPPING")}
            className={`order_addBtn ${
              selectedStatus === "SHIPPING" ? "active_order_addBtn" : ""
            }`}
          >
            SHIPPING
          </button>

          <button
            type="button"
            onClick={() => addFilterBtn("COMPLETED")}
            className={`order_addBtn ${
              selectedStatus === "COMPLETED" ? "active_order_addBtn" : ""
            }`}
          >
            COMPLETED
          </button>

          <button
            type="button"
            onClick={() => addFilterBtn("REJECTED")}
            className={`order_addBtn ${
              selectedStatus === "REJECTED" ? "active_order_addBtn" : ""
            }`}
          >
            REJECTED
          </button>

          <button
            type="button"
            onClick={() => addFilterBtn("CANCELLED")}
            className={`order_addBtn ${
              selectedStatus === "CANCELLED" ? "active_order_addBtn" : ""
            }`}
          >
            CANCELLED
          </button>
        </div>

        <TableContainer
          className={currentPageData?.length > 0 ? "table-container" : ""}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellRow}>Order No</TableCell>
                {/* <TableCell className={classes.cellRow}>Date</TableCell> */}
                <TableCell className={classes.cellRow}>Client</TableCell>
                <TableCell className={classes.cellRow}>Amount</TableCell>
                <TableCell className={classes.cellRow}>Order Status</TableCell>
                <TableCell className={classes.cellRow}>Payment Mode</TableCell>
                <TableCell className={classes.cellRow}>
                  Payment Status
                </TableCell>
                <TableCell className={classes.cellRow}>Order Actions</TableCell>
                <TableCell className={classes.cellRow}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderData?.length > 0 ? (
                currentPageData.filter((item) => selectedStatus ? item.orderStatus === selectedStatus : true)
                .map((item, i) => (
                  <TableRow key={item._id}>
                    <TableCell className={classes.cell}>
                      {item.orderNo.slice(0, 9)}
                    </TableCell>
                    {/* <TableCell className={classes.cell}>{formatDate(item?.date)}</TableCell> */}
                    <TableCell className={classes.cell}>
                      {item?.userId?.firstName}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {item?.totalPrice}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {item?.orderStatus}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {item?.paymentMode}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {item?.paymentStatus}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {gridConfirm(item)}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {gridItemAction(item)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No Data Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {filteredData?.length > 0 ? (
          <TablePagination
            count={filteredData.length}
            component="div"
            page={currentPage}
            rowsPerPage={pageSize}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[2]}
            showFirstButton={true}
            showLastButton={true}
            ActionsComponent={CustomPaginationActions}
          />
        ) : null}
        </TableContainer>

        {/* <GridComponent
          id="gridcomp"
          width="auto"
          // dataSource={itemsData}
          dataSource={orderData}
          allowPaging
          allowSorting
          allowExcelExport
          pageSettings={{ pageCount: 5 }}
          selectionSettings={selectionsettings}
          toolbar={toolbarOptions}
          allowPdfExport
          contextMenuItems={contextMenuItems}
          editSettings={editing}
        >
          <ColumnsDirective>
            {itemsGrid.map((item, index) => (
              <ColumnDirective key={index} {...item} />
            ))}
          </ColumnsDirective>
          <Inject
            services={[
              Resize,
              Search,
              ContextMenu,
              Page,
              Selection,
              Toolbar,
              Edit,
              Sort,
              Filter,
              ExcelExport,
              PdfExport,
            ]}
          />
        </GridComponent> */}
      </div>
    </div>
  );
};
export default Orders;
