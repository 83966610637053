import React, { useState, useEffect } from "react";
import "./SendNotificationTo.css";
import { Padding } from "@syncfusion/ej2-react-charts";
import axios from 'axios';

 
const SendNotificationTo = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedInputs, setSelectedInputs] = useState({
    title: '',
    body: ""
  });
 
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleInputChange = (e) => {
    setSelectedInputs({...selectedInputs, [e.target.name]: e.target.value });
  };

  const token = sessionStorage.getItem('myToken');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      "x-token": token,
    },
  }

  const loadData = async () => {
		const body = {
			"title": selectedInputs.title,
			"body": selectedInputs.body,
			"topic": selectedOption.toUpperCase(),
			// "pincode":"442603"
		}
    try{
      await axios
			.post(`https://api.orbitmart.co.in/api/v1/push_notification/notify`,body ,config)
    }catch(e){console.error(e)}
	};
 
  return (
    <>
      <div className="tableflex">
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
          <h1 className="page-heading">Send Notification To</h1>
          <div className="container">
            <div className="options">
              <label
                className={`option-label ${
                  selectedOption === "Merchant" ? "selected" : ""
                }`}
                onClick={() => handleOptionSelect("Merchant")}
              >
                <input
                  type="radio"
                  name="option"
                  value="Merchant"
                  checked={selectedOption === "Merchant"}
                  style={{ margin: "5px" }}
                  readOnly
                />
                Merchant
              </label>
              <label
                className={`option-label ${
                  selectedOption === "Employee" ? "selected" : ""
                }`}
                onClick={() => handleOptionSelect("Employee")}
              >
                <input
                  type="radio"
                  name="option"
                  value="Employee"
                  checked={selectedOption === "Employee"}
                  style={{ margin: "5px" }}
                  readOnly
                />
                Employee
              </label>
              <label
                className={`option-label ${
                  selectedOption === "User" ? "selected" : ""
                }`}
                onClick={() => handleOptionSelect("User")}
              >
                <input
                  type="radio"
                  name="option"
                  value="User"
                  checked={selectedOption === "User"}
                  style={{ margin: "5px" }}
                  readOnly
                />
                User
              </label>
            </div>
            <input
              type="text"
              className="textbox"
              name="title"
              placeholder="Enter text..."
              value={selectedInputs.title}
              onChange={handleInputChange}
            />
            <textarea
              className="textarea"
              placeholder="Enter text..."
              name="body"
              value={selectedInputs.body}
              onChange={handleInputChange}
            />
            <button className="button" onClick={loadData}>Send</button>
          </div>
        </div>
      </div>
    </>
  );
};
 
export default SendNotificationTo;
 